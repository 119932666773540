import * as client_hooks from '../../../src/hooks.client.js';
import * as universal_hooks from '../../../src/hooks.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31')
];

export const server_loads = [2,4,5];

export const dictionary = {
		"/(app)": [6,[2]],
		"/(app)/account": [7,[2,3]],
		"/(app)/account/addresses": [8,[2,3]],
		"/(app)/account/login": [9,[2,3]],
		"/(app)/account/orders": [10,[2,3]],
		"/(app)/account/orders/reorder/[order]": [12,[2,3]],
		"/(app)/account/orders/[order]": [11,[2,3]],
		"/(app)/account/subscriptions": [13,[2,3]],
		"/(app)/account/subscriptions/[subscription]": [14,[2,3,4]],
		"/(app)/account/subscriptions/[subscription]/edit": [~15,[2,3,4]],
		"/api/preview/disable": [~30],
		"/api/reviews/create": [31],
		"/(app)/cart": [~16,[2]],
		"/(app)/checkout": [17,[2,5]],
		"/(app)/checkout/user": [18,[2,5]],
		"/(app)/collections/[collection]": [19,[2]],
		"/(app)/game": [~20,[2]],
		"/(app)/game/play": [~21,[2]],
		"/(app)/pages/coffee-shop": [23,[2]],
		"/(app)/pages/coffee-shop/[location]": [24,[2]],
		"/(app)/pages/contact": [25,[2]],
		"/(app)/pages/reviews": [26,[2]],
		"/(app)/pages/tracking": [~27,[2]],
		"/(app)/pages/[page]": [22,[2]],
		"/(app)/products/[product]": [28,[2]],
		"/(app)/search": [29,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: universal_hooks.reroute || (() => {}),
	transport: universal_hooks.transport || {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';